"use client";
import cn from "@/utils/cn";
import { PrismicImage, PrismicRichText } from "@prismicio/react";
import { useState } from "react";

const FeatureCard = ({ slice }: any) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const container = event.currentTarget;
    const scrollLeft = container.scrollLeft;
    const cardWidth = 280;
    const newSlide = Math.round(scrollLeft / cardWidth);
    setCurrentSlide(newSlide);

    const cards = container.querySelectorAll(".card");
    cards.forEach((card, index) => {
      const distance = Math.abs(index - newSlide);
      const opacity = distance === 0 ? 1 : 0.5;
      card.classList.remove("opacity-100", "opacity-50", "md:opacity-100");
      card.classList.add(
        `opacity-${Math.round(opacity * 100)}`,
        "md:opacity-100"
      );
    });
  };
  return (
    <section
      className="bg-[#FAFBFC] py-8 md:py-[65px] "
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
    >
      <div className="mx-auto max-w-[1120px] flex-col items-center  justify-center px-4 sm:px-6 md:flex md:px-0">
        {/* <div className="px-4 sm:px-6 md:px-9 xl:px-20"> */}
        <div className="mb-6 text-center md:mb-8">
          <div className="mb-[2px] text-[25px] font-semibold  leading-[30px] text-kd-lte-blue md:text-[27px] md:leading-[32px] xl:text-[40px] xl:leading-[51px]">
            <PrismicRichText field={slice.primary.title} />
          </div>
          <div className="text-[10.8px] font-medium text-kd-gray-text xl:text-sm">
            <PrismicRichText field={slice.primary.description} />
          </div>
        </div>
        <div
          className="flex snap-x snap-mandatory overflow-x-auto scroll-smooth px-40 scrollbar-hide md:grid md:grid-cols-3 md:gap-[21px] md:px-0 xl:gap-[33px] "
          onScroll={handleScroll}
        >
          {/* <div className=" flex flex-wrap gap-8 bg-black justify-center"> */}
          {slice.items.map((item: any, index: any) => (
            <div
              key={index}
              className={cn(
                "card flex-shrink-0 w-[260px] h-[240px] snap-center md:w-[210px] md:h-[290px] xl:w-[352px] xl:h-[384px] flex flex-col bg-white overflow-hidden rounded-[13px] xl:rounded-[20px] border-[#EDF2F6] border pb-4 md:pb-[18px] px-4 md:px-5 opacity-50 md:opacity-100 transition duration-300",
                index == currentSlide
                  ? "border border-kd-lte-blue md:border-none"
                  : ""
              )}
            >
              <div className="mb-4 mt-6 md:mb-[14px] md:mt-[20px] xl:mb-[22px] xl:mt-[30px]">
                <PrismicImage
                  field={item.icon_image}
                  className="h-[38px] w-[37px] xl:h-[59px] xl:w-[56px]"
                />
              </div>
              <div className="text-[12px] font-semibold leading-[20px] text-kd-lte-blue xl:text-[20px] xl:leading-[32px] ">
                <PrismicRichText field={item.feature_title} />
              </div>
              <div className="mt-[2.4px] text-[9px] leading-[16px] text-kd-gray-text xl:mt-1 xl:text-[13px] xl:leading-[25px]">
                <PrismicRichText field={item.feature_description} />
              </div>
            </div>
          ))}
        </div>
        <div className="mt-4 flex justify-center md:hidden">
          {slice.items.map((_: any, index: any) => (
            <div
              key={index}
              className={`mx-1 h-[5.7px] w-[6px] rounded-full bg-gray-300 ${
                index === currentSlide ? "bg-kd-lte-blue" : ""
              }`}
            ></div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeatureCard;
