import cn from "@/utils/cn";
import { SplitSectionProps } from "./utils";
import { PrismicNextImage } from "@prismicio/next";

type imageLeftProps = {
  imageField: SplitSectionProps["slice"]["primary"]["image"];
  contentFragment: React.ReactNode;
  imagePosition: "Left" | "Right";
  imageFlushWithEdge: boolean;
  backgroundColor: "White" | "Grey" | "buttonVarient";
} & React.HTMLAttributes<HTMLDivElement>;

export default function SplitContainer({
  imageField,
  contentFragment,
  imagePosition,
  imageFlushWithEdge,
  backgroundColor,
  className,
  ...props
}: imageLeftProps) {
  return (
    <div
      className={cn(className, "md:min-h-[424px] lg:min-h-[582px]")}
      {...props}
    >
      {/* Mobile component */}
      <div className="flex flex-col items-center space-y-6 py-6 md:hidden">
        <ContentFirstContainer
          imageFlushWithEdge={imageFlushWithEdge}
          contentFragment={contentFragment}
          imageField={imageField}
          imagePosition={imagePosition}
          backgroundColor={backgroundColor}
        />
      </div>

      <div
        className={cn(
          "hidden flex-col items-center justify-between space-y-10 xxpt-20  md:flex-row xl:space-x-10 md:space-x-6 md:flex ",
          // imageFlushWithEdge && "space-x-6",
          backgroundColor === "White"
            ? "bg-white"
            : backgroundColor === "buttonVarient"
              ? "bg-gradient-to-t from-[#4A9DFF] to-[#336DFF]"
              : "bg-[#FAFAFA]"
        )}
      >
        {imagePosition === "Left" ? (
          <div className="px-10 md:px-[62px] lg:px-[80px] w-full  flex items-center justify-center space-x-8 pt-[86px] md:py-[69px] lg:py-[104px]">
            <div className="flex justify-between  items-center w-[300px] h-[262px] lg:w-[544px] lg:h-[374px]">
              <PrismicNextImage className={cn("")} field={imageField} alt="" />
            </div>
            <div className={cn(" w-fulls lg:w-[544px] w-1/2")}>
              {contentFragment}
            </div>
          </div>
        ) : (
          <ContentFirstContainer
            imageFlushWithEdge={imageFlushWithEdge}
            contentFragment={contentFragment}
            imageField={imageField}
            imagePosition={imagePosition}
            backgroundColor={backgroundColor}
          />
        )}
      </div>
    </div>
  );
}

type ContentFirstContainerProps = {
  imageField: SplitSectionProps["slice"]["primary"]["image"];
  contentFragment: React.ReactNode;
  imagePosition: "Left" | "Right";
  imageFlushWithEdge: boolean;
  backgroundColor: "White" | "Grey" | "buttonVarient";
} & React.HTMLAttributes<HTMLDivElement>;

// Mobile default container
function ContentFirstContainer({
  imageFlushWithEdge,
  contentFragment,
  imageField,
  imagePosition,
  backgroundColor,
}: ContentFirstContainerProps) {
  return (
    <div
      className={cn(
        "flex md:flex-row flex-col items-center justify-center w-full pt-[86px] md:py-[70px] lg:py-[104px] md:px-[62px] lg:px-[80px]",
        backgroundColor === "buttonVarient"
          ? "bg-gradient-to-t from-[#4A9DFF] to-[#336DFF]"
          : ""
      )}
    >
      <div className={cn("w-full px-10 lg:px-0 md:mr-[34px] md:w-[544px] ")}>
        {contentFragment}
      </div>
      <div
        className={cn(
          "flex justify-center items-center w-[300px] h-[262px] md:w-[544px] md:h-[374px]   "
        )}
      >
        <PrismicNextImage field={imageField} alt="" />
      </div>
    </div>
  );
}
