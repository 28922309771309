"use client";

import cn from "@/utils/cn";

import { SplitSectionProps, parseVariationDetailsFromSlice } from "./utils";
import { useMemo } from "react";
import { PrismicRichText } from "@prismicio/react";
import SplitContainer from "./split-container";
import Image from "next/image";

import checkIcon from "@/assets/icons/check-icon.svg";
import Button from "@/components/buttons";
import Link from "next/link";

type SplitSectionInternalProps = {
  slice: SplitSectionProps["slice"];
  className?: string;
} & React.HTMLAttributes<HTMLDivElement>;

export default function SplitSectionInternal({
  slice,
  className,
  ...props
}: SplitSectionInternalProps) {
  const variationDetails = useMemo(
    () => parseVariationDetailsFromSlice(slice),
    [slice],
  );

  return (
    <div className={cn("", className)} {...props}>
      <SplitContainer
        contentFragment={
          <>
            {variationDetails.contentLabel && (
              <PrismicRichText
                field={variationDetails.contentLabel}
                components={{
                  paragraph: ({ children }) => (
                    <p className="text-justify text-[12px] font-semibold leading-[27px] text-[#96A5B8] md:text-[13px] xl:text-[20px]">
                      {children}
                    </p>
                  ),
                }}
              />
            )}
            <div className="mb-4">
              <PrismicRichText
                field={slice.primary.content_title}
                components={{
                  paragraph: ({ children }) => (
                    <p
                      className={cn(
                        "text-[20px] md:text-[25px] xl:text-[34px] font-semibold leading-[25px] md:leading-[30px] xl:leading-[40px] text-[#05004E] md:pr-1",
                        // "text-[25px] leading-[35px] md:text-[27px] xl:text-[40px] xl:leading-[40px]",
                        slice.variation === "imageRightWithButton"
                          ? "text-[#FFFFFF]"
                          : "",
                      )}
                    >
                      {children}
                    </p>
                  ),
                }}
              />
            </div>
            <div className="">
              <PrismicRichText
                field={slice.primary.content_description}
                components={{
                  paragraph: ({ children }) => (
                    <p
                      className={cn(
                        // "mb-4 text-[10.8px] leading-[18px] xl:leading-6 text-[#444A6D] xl:text-base xl:leading-7 ",
                        "mb-4 text-[10.8px] mt-[7.42px] md:mt-[9px] lg:mt-[6.33px] xl:text-sm leading-[18px] xl:leading-6 text-[#444A6D] xl:text-base xl:leading-7 ",
                        slice.variation === "imageRightWithButton"
                          ? "text-[#D1D1D6]"
                          : "",
                      )}
                    >
                      {children}
                    </p>
                  ),
                  heading6: ({ children }) => (
                    <h6 className="mb-4 text-[13px] font-medium text-[#61A1FF] xl:text-lg">
                      {children}
                    </h6>
                  ),
                  strong: ({ children }) => (
                    <strong className="text-[11px] font-bold xl:text-base">
                      {children}
                    </strong>
                  ),
                  list: ({ children }) => (
                    <ul className="mb-2 list-inside list-disc">{children}</ul>
                  ),
                  listItem: ({ children }) => (
                    <li className="mb-[10px] flex items-center space-x-4 text-[11px] font-semibold text-[#222A41] xl:mb-4 xl:text-base xl:leading-[27px]">
                      <Image src={checkIcon} alt="" className="w-5 xl:w-8" />
                      <div>{children}</div>
                    </li>
                  ),
                }}
              />
            </div>

            {variationDetails.subTitle && (
              <PrismicRichText
                field={variationDetails.subTitle}
                components={{
                  paragraph: ({ children }) => (
                    <p className="text-justify text-[12px] font-semibold leading-[18px] text-white md:text-[13px] md:leading-[19.5px] xl:text-[16px] xl:leading-[24px]">
                      {children}
                    </p>
                  ),
                }}
              />
            )}

            {variationDetails.subDescription && (
              <PrismicRichText
                field={variationDetails.subDescription}
                components={{
                  paragraph: ({ children }) => (
                    <p
                      className={cn(
                        "mb-4 text-[10.8px] mt-[7.42px] md:mt-[9px] lg:mt-[6.33px] xl:text-sm leading-[18px] xl:leading-6 text-[#444A6D] xl:text-base xl:leading-7 ",
                        slice.variation === "imageRightWithButton"
                          ? "text-[#D1D1D6]"
                          : "",
                      )}
                    >
                      {children}
                    </p>
                  ),
                }}
              />
            )}
            {variationDetails.buttonText && (
              <PrismicRichText
                field={variationDetails.buttonText}
                components={{
                  paragraph: ({ children }) => (
                    <Link href={"/pricing"}>
                      <Button
                        variant="primary"
                        className="mb-[13px] mt-2 h-[32px] rounded-[9.12px] border border-[#61A1FF] bg-kd-lte-blue px-[13px] py-2 text-[12.8px] font-semibold leading-6 text-white md:mt-[6px] xl:mt-[14px] xl:h-10 xl:rounded-[12px] xl:px-[15px] xl:text-base"
                      >
                        {children}
                      </Button>
                    </Link>
                  ),
                }}
              />
            )}
          </>
        }
        imageField={slice.primary.image}
        imagePosition={variationDetails.imagePosition}
        imageFlushWithEdge={variationDetails.imageFlushWithEdge}
        backgroundColor={
          variationDetails.buttonText
            ? "buttonVarient"
            : variationDetails.backgroundColor
        }
      />
    </div>
  );
}
