"use client";

import Button from "@/components/buttons";
import InputField from "@/components/input-field";
import cn from "@/utils/cn";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { EmailFormSchema, emailFormSchema } from "@/utils/schemas/form-schema";
import { handleEmailFormSubmit } from "@/utils/actions/mailchimp-integration";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { BsCheckCircleFill } from "react-icons/bs";

type EmailCaptureFormProps = React.FormHTMLAttributes<HTMLFormElement>;

export default function EmailCaptureForm({
  className,
  ...props
}: EmailCaptureFormProps) {
  const methods = useForm<EmailFormSchema>({
    resolver: zodResolver(emailFormSchema),
  });

  const [formProcessing, setFormProcessing] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (showSuccessMessage) {
      timeout = setTimeout(() => setShowSuccessMessage(false), 5000);
    }
    return () => clearTimeout(timeout);
  }, [showSuccessMessage]);

  return (
    <div className="relative">
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(async (data) => {
            setFormProcessing(true);
            let status;
            try {
              const response = await handleEmailFormSubmit(data); // DEV: We cannot directly pass the function to onSubmit due to some actions-ey stuff. Figure out later.
              status = response.status;
              // console.log("response", response);
              // toast.success("Thanks, you're in!", {
              //   duration: 5000,
              //   style: {
              //     position: "absolute",
              //   },
              // });
              setShowSuccessMessage(true);
              methods.reset();
            } catch (error) {
              // toast({ message: error.message, type: "error" });
              // Don't wanna log the error here, since this is a 'use client' file, any errors logged here will be visible in the browser console, even in production. We don't want that. The 'use server' files are already logging these errors, and are presenting them in the EC2 PM2 logs.
            } finally {
              setFormProcessing(false);
            }
            if (status === "success") {
              methods.reset();
            } else {
              // Here, message necessarily contains an error message.
              // toast({ message: message as string, type: "error" });
            }
          })}
          className={cn("", className)}
          {...props}
        >
          <div className="mt-4 flex flex-col items-center justify-center space-y-4 md:flex-row md:items-end md:space-x-4 md:space-y-0">
            <InputField
              name="name"
              className="h-[32px] rounded-[9.6px] border border-[#EDF2F6] md:w-[240px] lg:h-10 lg:rounded-[12px] xl:w-[287.41px] xl:pb-2.5 xl:pt-3"
              type="text"
              placeholder="First name"
            />
            <InputField
              name="email"
              type="email"
              className="h-[32px] rounded-[9.6px] border border-[#EDF2F6] md:w-[240px] lg:h-10 lg:rounded-[12px] xl:w-[287.41px] xl:pb-2.5 xl:pt-3"
              placeholder="Email"
            />
            <Button
              type="submit"
              variant="primary"
              className="h-[32px] whitespace-nowrap rounded-[9.6px] border border-[#61A1FF] px-[12.5px] lg:h-10 lg:rounded-[12px] lg:px-[15px]"
              disabled={formProcessing}
            >
              Join the Waitlist
            </Button>
          </div>
        </form>
      </FormProvider>

      {showSuccessMessage && <SuccessMessageToast />}
    </div>
  );
}

const SuccessMessageToast = () => {
  return (
    <div className=" -bottom-12 flex w-full  justify-center   pt-4 text-center text-sm font-semibold md:w-[77%] xl:text-base">
      <div className=" w-fit rounded-lg  border border-[#EDF2F6] bg-[#F6F6F6] px-2 py-1 text-kd-lte-blue">
        <BsCheckCircleFill className="mr-1 inline text-[16px] text-green-400" />
        {`Thanks, you're in!`}
      </div>
    </div>
  );
};
