"use client";
import cn from "@/utils/cn";
import Image from "next/image";
import { useState } from "react";

const ButtonBorder = ({ className = "" }) => (
  <div className={cn(className)}>
    <svg
      width="43"
      height="26"
      viewBox="0 0 43 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30 1H13C9.27247 1 7.4087 1 5.93853 1.60896C3.97831 2.42092 2.42092 3.97831 1.60896 5.93853C1 7.4087 1 9.27247 1 13C1 16.7275 1 18.5913 1.60896 20.0615C2.42092 22.0217 3.97831 23.5791 5.93853 24.391C7.4087 25 9.27247 25 13 25H30C33.7275 25 35.5913 25 37.0615 24.391C39.0217 23.5791 40.5791 22.0217 41.391 20.0615C42 18.5913 42 16.7275 42 13C42 9.27247 42 7.4087 41.391 5.93853C40.5791 3.97831 39.0217 2.42092 37.0615 1.60896C35.5913 1 33.7275 1 30 1Z"
        fill="white"
        stroke="#336DFF"
      />
    </svg>
  </div>
);
const Button = ({ className = "" }) => (
  <div className={cn(className)}>
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.25 1.5H10.75C14.5 1.5 16 3.01293 16 6.79526V11.3341C16 15.1164 14.5 16.6293 10.75 16.6293H6.25C2.5 16.6293 1 15.1164 1 11.3341V6.79526C1 3.01293 2.5 1.5 6.25 1.5Z"
        fill="#336DFF"
        stroke="#336DFF"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

const BillingToggle = ({ isMonthly, setIsMonthly, className = "" }: any) => {
  return (
    <div className={cn("flex items-center justify-center mt-8", className)}>
      <label
        className="flex items-center cursor-pointer text-[14px] leading-[28px]"
        onClick={() => setIsMonthly(!isMonthly)}
      >
        <span
          className={cn(
            "mr-3 ",
            isMonthly ? "text-[#444A6D] font-medium" : "text-[#96A5B8]"
          )}
        >
          Monthly
        </span>

        <div className="relative">
          <ButtonBorder />
          <Button
            className={cn(
              "absolute top-1 ",
              isMonthly ? "left-[5px]" : "right-[5px]"
            )}
          />
        </div>
        <span
          className={cn(
            "ml-3 ",
            !isMonthly ? "text-[#444A6D] font-medium" : "text-[#96A5B8]"
          )}
        >
          Annually
        </span>
      </label>
    </div>
  );
};

export default BillingToggle;
