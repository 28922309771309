"use client";
import { useState } from "react";
import BillingToggle from "./_components/BillingToggle";
import { PricingPageDocumentData } from "../../../prismicio-types";
import { PrismicRichText } from "@prismicio/react";
import { PricingPlanComponentProp } from "./utils";
import Button from "@/components/buttons";
import Link from "next/link";
import { useZStore } from "@/utils/z-store";

type PricingPlanProp = {
  items: PricingPlanComponentProp["slice"]["items"];
  title: PricingPageDocumentData["hero_title"];
  description: PricingPageDocumentData["hero_description"];
};
const PricingPlans = ({ items, title, description }: PricingPlanProp) => {
  const [isMonthly, setIsMonthly] = useState(false);
  const { actions } = useZStore();

  return (
    <div className="bg-[#FAFBFC] px-[39px]  py-[62px] md:px-[36px] lg:px-20 xl:px-0">
      <div className=" relative  m-auto max-w-[1120px]">
        <div className="text-center">
          <div className="text-[25px] font-semibold leading-[30px] text-kd-lte-blue md:text-[27px] md:leading-[32.5px] xl:text-[40px] xl:leading-[50px]">
            <PrismicRichText field={title} />
          </div>
          <div className="mt-[2px] text-[10.8px] leading-[18px] text-kd-gray-text md:text-[10.8px] md:leading-[18px] xl:text-[14px] xl:leading-[24px]">
            <PrismicRichText field={description} />
          </div>
        </div>

        <BillingToggle
          isMonthly={isMonthly}
          setIsMonthly={setIsMonthly}
          className="absolute right-16 top-4 hidden lg:block"
        />

        <div className="mt-10  flex flex-col items-center justify-between md:flex-row lg:space-y-0 ">
          {items.map((item: any, index: any) => {
            return (
              <div
                key={index}
                className="relative my-[18px]  flex h-[290px] w-[260px] flex-col justify-between rounded-2xl border-[0.65px] border-[#EDF2F6] bg-white px-[14px] pb-[20px] pt-[35px]  shadow-sm md:min-h-[290px] md:min-w-[210px] xl:h-[454px] xl:w-[352px] xl:px-[32px] xl:pb-[48px] xl:pt-[54px]"
              >
                <div className="">
                  <div className="text-[20px] font-semibold leading-[20.8px] text-[#05004E] md:text-[20px] md:leading-[20.8px] xl:text-[30px] xl:leading-[32px]">
                    <PrismicRichText field={item.title} />
                  </div>
                  <div className="mt-[28px] flex items-baseline text-[#336DFF] xl:mt-[34px]">
                    <div className=" text-left text-[35px] font-semibold leading-[20.8px] xl:text-[50px] xl:leading-[32px]">
                      <sup className="text-[20px] xl:text-[32px]">$</sup>
                      {isMonthly
                        ? item.monthly_price[0].text
                        : item.annual_price[0].text}
                      {/* <PrismicRichText field={item.annual_price} /> */}
                    </div>
                    <span className=" ml-1 text-left text-[9px] font-semibold leading-[20.8px] xl:text-[14px] xl:leading-[32px]">
                      / month
                    </span>
                  </div>

                  <div className=" mt-0 text-left text-[9px] font-medium leading-[20.8px] text-[#336DFF] xl:text-[14px] xl:leading-[32px]">
                    <PrismicRichText
                      field={isMonthly ? item.monthly_text : item.annually_text}
                    />
                    {/* {`Save 40% on Annual prices`} */}
                  </div>
                  <div className=" mt-2 text-left text-[9px] leading-[18px] text-[#444A6D] xl:text-[14px] xl:leading-[28px]">
                    <div className=" font-semibold ">
                      <PrismicRichText field={item.subtitle} />
                    </div>
                    <div className="">
                      <PrismicRichText field={item.description} />
                    </div>
                  </div>
                </div>

                <div className="flex items-center justify-between space-x-[14px] md:space-x-[11px] xl:space-x-[21px]">
                  <Button
                    variant="primary"
                    className="w-full whitespace-nowrap"
                    onClick={() =>
                      actions.openModal({ modalName: "start-free-trial" })
                    }
                  >
                    Buy Now
                  </Button>

                  <Button
                    variant="secondary"
                    className="w-full whitespace-nowrap"
                    onClick={() =>
                      actions.openModal({ modalName: "start-free-trial" })
                    }
                  >
                    {isMonthly ? "Start Free Trial" : "14 Day Free Trial"}
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PricingPlans;
