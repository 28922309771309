import { Content, RichTextField } from "@prismicio/client";
import { SliceComponentProps } from "@prismicio/react";

/**
 * Props for `SplitSection`.
 */
export type SplitSectionProps = SliceComponentProps<Content.SplitSectionSlice>;

// Variation facets:
// - image position (left or right)
// - background color (white or grey)
// - image flush with edge (true or false)
// - content label (string or undefined)

// We are experimenting with 2 mechanisms- First mechanism, for the first 2 fields, i.e. image position and background color, we are using the variation field. For the last 2 fields, i.e. image flush with edge and content label, we are using dropdowns in the primary field. Second mechanism, there are no variations, all the facets are handled by dropdowns in the primary field.

// The second mechanism is super convenient for setting up and makes a lot of sense, but it doesn't tap into the native variation functionality of Prismic. The first mechanism is less convenient and less extendable since imagine if we have 3 facets of one field, and 2 facets of another field, we would have to create 6 variations in Prismic, which is not ideal. Extend this to more facets, and suddenly we have too many variations to take care of. But it does taps into the native variation functionality of Prismic, so we should keep it in for now.

// This function soothes over both these approaches and gives us a clean object with all the details of the variation facets.

type VariationDetails = {
  imagePosition: "Left" | "Right";
  backgroundColor: "White" | "Grey";
  imageFlushWithEdge: boolean;
  contentLabel: RichTextField | undefined;
  subTitle: RichTextField | undefined;
  subDescription: RichTextField | undefined;
  buttonText: RichTextField | undefined;
};

export const parseVariationDetailsFromSlice = (
  slice: SplitSectionProps["slice"]
): VariationDetails => {
  // Initializing with defaults.
  const variationDetails: VariationDetails = {
    imagePosition: "Left",
    backgroundColor: "White",
    imageFlushWithEdge: false,
    contentLabel: undefined,
    subTitle: undefined,
    subDescription: undefined,
    buttonText: undefined,
  };

  // Common fields for both variations.
  variationDetails.imageFlushWithEdge = slice.primary.image_flush_with_edge;
  variationDetails.contentLabel =
    slice.primary.content_label_optional.length > 0
      ? slice.primary.content_label_optional
      : undefined;

  if (slice.variation === "default") {
    // All the facets are handled by dropdowns in the primary field.
    variationDetails.imagePosition = slice.primary.image_position;
    variationDetails.backgroundColor = slice.primary.background_color;

    return variationDetails;
  }
  if (slice.variation === "imageRightWithButton") {
    variationDetails.imagePosition = slice.primary.image_position;
    variationDetails.subTitle = slice.primary.sub_title;
    variationDetails.subDescription = slice.primary.sub_description;
    variationDetails.buttonText = slice.primary.button_text;
  } else {
    // First 2 facets i.e. image position and background color are handled by the variation field.
    variationDetails.imagePosition = slice.variation.includes("imageLeft")
      ? "Left"
      : "Right";
    variationDetails.backgroundColor = slice.variation.includes(
      "WhiteBackground"
    )
      ? "White"
      : "Grey";
  }

  return variationDetails;
};
