import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/node_modules/@prismicio/next/dist/PrismicNextImage.js");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/node_modules/@prismicio/next/dist/PrismicNextLink.js");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/node_modules/@prismicio/next/dist/PrismicPreviewClient.js");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/node_modules/@prismicio/react/dist/PrismicToolbar.js");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/src/app/_components/modal-wrapper.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/src/assets/bg-left-image.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/src/assets/bg-right-image.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/src/assets/landing-page-mobile-bg.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/src/components/SessionWrapper.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/src/slices/Faq/faq-component.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/src/slices/MarketingForm/hero-section/email-capture-form.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/src/slices/PlatformFeatures/feature-card.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/src/slices/Pricing/pricing-plans.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/src/slices/SplitSection/split-section-internal.tsx")