"use client";
import cn from "@/utils/cn";
import { PrismicRichText } from "@prismicio/react";
import { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const FaqItem = ({ question, answer, index, isExpanded, handleClick }: any) => {
  return (
    <div
      className={cn(
        "cursor-pointer text-[#444A6D] text-[10.8px] font-medium leading-[15px] xl:text-[14px] xl:leading-[20px]"
      )}
      onClick={handleClick}
    >
      <div
        className={cn(
          "flex justify-between items-center px-[18px] md:px-[18px] bg-[#FAFBFC] lg:px-[24px] py-[16.25px] md:py-[16px] lg:py-[22px] ",
          isExpanded
            ? "bg-kd-lte-blue text-white font-semibold"
            : "hover:bg-[#EDF2F6] hover:text-kd-lte-blue"
        )}
      >
        <div className="flex  space-x-[41px] md:space-x-[43px] lg:space-x-[54px]">
          <div className="">{`${String(index + 1).padStart(2, "0")} `}</div>
          <PrismicRichText field={question} />
        </div>

        <div className="">
          {isExpanded ? <FaChevronUp /> : <FaChevronDown />}
        </div>
      </div>

      {isExpanded && answer && (
        <div className="cursor-default bg-white py-[18px] text-[9px] font-medium leading-[16px] text-gray-700 md:py-[18px] xl:py-[23px] xl:text-[13px] xl:leading-[25px]">
          <PrismicRichText field={answer} />
        </div>
      )}
    </div>
  );
};

const FaqComponent = ({ title, items }: any) => {
  const [expandedIndex, setExpandedIndex] = useState(null); // Initialize with the third question expanded

  const handleExpandClick = (index: any) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  // px-[41px]  md:px-[62px] lg:px-20
  return (
    <div className="mx-auto max-w-[1120px] px-[39px] py-[76px] md:px-[36px] lg:px-20 xl:px-0">
      <h2
        // className="text-3xl font-bold mb-8 text-blue-600"
        className="mb-[31px] text-center text-xl font-semibold leading-[25px] text-[#05004E] md:mb-[27px] md:text-[25px] md:leading-[37.5px] lg:mb-[36px] xl:text-[34px] xl:leading-[50px]"
      >
        <PrismicRichText field={title} />
      </h2>
      <div className="divide-y bg-[#FAFBFC] ">
        {items.map((faq: any, index: any) => (
          <FaqItem
            key={index}
            question={faq.question}
            answer={faq.answer}
            index={index}
            isExpanded={expandedIndex === index}
            handleClick={() => handleExpandClick(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default FaqComponent;
